import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components";
import { slide as Menu } from 'react-burger-menu'
import {
  useWindowSize,
} from '@react-hook/window-size'
import header from '../images/header.png';
import NavLink from './elements/navLink';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHamburger } from "@fortawesome/free-solid-svg-icons";

const MenuStyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    right: '36px',
    top: '36px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#000'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: 0,
  },
  bmMenu: {
    background: '#fafafa',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.9)'
  }
};

const Wrapper = styled.header`
  flex: 1;
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem 0;
  position: fixed;
  top: 0;
  z-index: 100;
`;

const LogoWrapper = styled(Link)`
  & > img {
    height: 3rem;
    width: auto;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > a {
    margin: 0 0.3rem;
    text-transform: uppercase;
    text-decoration: none;
  }
`

const BurgerIcon = styled.div(
  () => css`
    display: block;

    & svg {
      color: #a90000;
      font-size: 32px;
    }
  `
)

const BasicMenu = () => (
  <>
    <NavLink to="/">Home</NavLink>
    <NavLink to="/blog/">Blog</NavLink>
    <NavLink to="/cheatsheets/">Taháky</NavLink>
    {/* <NavLink to="/links/">Links</NavLink> */}
    {/* <NavLink to="/notion/">Notion</NavLink> */}
    <NavLink to="/beskydske-tury/">Beskydské&nbsp;túry</NavLink>
  </>
)

const BurgerMenu = () => (
  <Menu styles={MenuStyles} customBurgerIcon={<BurgerIcon><FontAwesomeIcon icon={faHamburger} style={{ color: '#a90000' }} /></BurgerIcon>} right>
    <BasicMenu />
  </Menu>
)

const Header = () => {
  const [width] = useWindowSize()
  return (
    <Wrapper
    >
      <LogoWrapper to="/">
        <img src={header} alt="logo" />
      </LogoWrapper>
      <LinkWrapper>
        {
          width > 640
          ? <BasicMenu />
          : <BurgerMenu />
        }
      </LinkWrapper>
    </Wrapper>
  )
}

export default Header
