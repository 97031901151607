import { Link } from "gatsby"
import React from "react"
import styled from "styled-components";

const Wrapper = styled.div`
  display: block;
  width: 100%;
`;

const NavigationLink = styled(props => <Link {...props} />)`
  display: block;
  font-size: 1.25rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  line-height: 2;
  border-bottom: 1px solid #C6EBEF;
  box-shadow: inset 0 -7px 0 #C6EBEF;
  color: black;
  transition: all .25s ease-in-out;
  padding: 2px 2px 0 2px;
  margin: 0 0.5rem;
  text-transform: uppercase;
  text-decoration: none;
  &:hover {
    background-color: #C6EBEF;
  }

  @media screen and (min-width: 641px) {
    font-size: 0.85rem;
    line-height: 1;
  }
`

type Props = {
  children: React.ReactNode,
  to: string,
}

const NavLink = ({ children, to }: Props) => (
  <Wrapper>
    <NavigationLink to={to} >
      {children}
    </NavigationLink>
  </Wrapper>
)

export default NavLink
