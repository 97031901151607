import React from "react"
import styled from "styled-components";
import Emoji from './elements/emoji';

const FooterWrapper = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  background-color: #f5f5f5;
  bottom: 0;

  & > span {
    font-size: 0.75rem;
    font-family: 'Montserrat', sans-serif;
  }
`

const Footer = () => (
  <FooterWrapper>
    <span>
      Made with Gatsby and <Emoji label="heart" symbol="❤️" />.
    </span>
  </FooterWrapper>
)

export default Footer
