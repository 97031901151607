import React from "react"
import styled from "styled-components"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import Instagram from "./instagram"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  min-height: 100vh;
`

const Container = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-top: 8.5rem;
`

const MainWrapper = styled.div`
  margin: 0 auto;
`

const Main = styled.main`
  max-width: 35rem;
  padding: 0 1.5rem;
`

type Props = {
  showIG?: boolean;
  children: React.ReactNode;
}

const Layout = ({ showIG, children }: Props) => {
  return (
    <Wrapper>
    <Header/>
    <Container>
      <MainWrapper>
        <Main>{children}</Main>
      </MainWrapper>
      {/* {showIG && <Instagram />} */}
      <Footer />
    </Container>
    </Wrapper>
  )
}

export default Layout
